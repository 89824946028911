import React, { useState } from 'react';
import { Box } from '@mui/material';
import ChatList from '../../components/Chats/ChatList';
import ChatDetails from '../../components/Chats/ChatDetails';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import avatarmen from '../../assets/avatarmen.jpg';
import userAvatar from '../../assets/man.png';


const Chat = () => {
  const [selectedChat, setSelectedChat] = useState(null);

  // Handle when a user clicks on a chat in the list
  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  const users = [
    { 
      id: 1, 
      name: 'Pavan', 
      lastMessage: 'Hey, how are you?', 
      messages: [
        { id: 1, sender: 'Pavan', avatar: avatarmen, message: 'Hey, how are you?', timestamp: '10:35 pm' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'I’m good, thanks! How about you?', timestamp: '10:36 pm' },
        { id: 3, sender: 'Pavan', avatar: avatarmen, message: 'Doing great! Let’s catch up soon.', timestamp: '10:38 pm' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Sure, let me know when.', timestamp: '10:39 pm' },
        { id: 5, sender: 'Pavan', avatar: avatarmen, message: 'Will do!', timestamp: '10:40 pm' },
      ]
    },
    { 
      id: 2, 
      name: 'Bharath', 
      lastMessage: 'Let’s catch up tomorrow!',
      messages: [
        { id: 1, sender: 'Bharath', avatar: avatarmen, message: 'Let’s catch up tomorrow!', timestamp: '10:50 pm' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Sure! What time?', timestamp: '10:51 pm' },
        { id: 3, sender: 'Bharath', avatar: avatarmen, message: 'How about 3 PM?', timestamp: '10:52 pm' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Perfect, see you then!', timestamp: '10:53 pm' },
      ]
    },
    { 
      id: 3, 
      name: 'Sandeep', 
      lastMessage: 'Meeting rescheduled.',
      messages: [
        { id: 1, sender: 'Sandeep', avatar: avatarmen, message: 'Meeting rescheduled.', timestamp: '9:00 am' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Oh, when is the new time?', timestamp: '9:02 am' },
        { id: 3, sender: 'Sandeep', avatar: avatarmen, message: 'It’s now at 11 AM.', timestamp: '9:05 am' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Got it, thanks for the update!', timestamp: '9:07 am' },
      ]
    },
    { 
      id: 4, 
      name: 'Gaurav', 
      lastMessage: 'Great job on the project!',
      messages: [
        { id: 1, sender: 'Gaurav', avatar: avatarmen, message: 'Great job on the project!', timestamp: '11:20 am' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Thanks, it was a team effort!', timestamp: '11:22 am' },
        { id: 3, sender: 'Gaurav', avatar: avatarmen, message: 'Absolutely! Let’s keep it up.', timestamp: '11:23 am' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'For sure, more to come!', timestamp: '11:25 am' },
      ]
    },
    { 
      id: 5, 
      name: 'Sunil Chetri', 
      lastMessage: 'I’ll call you later.',
      messages: [
        { id: 1, sender: 'Sunil Chetri', avatar: avatarmen, message: 'I’ll call you later.', timestamp: '7:15 pm' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Alright, I’ll wait for your call.', timestamp: '7:17 pm' },
        { id: 3, sender: 'Sunil Chetri', avatar: avatarmen, message: 'Call me when you are free.', timestamp: '7:20 pm' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Sure, I’ll let you know.', timestamp: '7:22 pm' },
      ]
    },
    { 
      id: 6, 
      name: 'Safvan', 
      lastMessage: 'Got it, thanks!',
      messages: [
        { id: 1, sender: 'Safvan', avatar: avatarmen, message: 'Got it, thanks!', timestamp: '8:00 pm' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Anytime, glad I could help!', timestamp: '8:02 pm' },
        { id: 3, sender: 'Safvan', avatar: avatarmen, message: 'I’ll get started on it now.', timestamp: '8:05 pm' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Great, looking forward to it!', timestamp: '8:06 pm' },
      ]
    },
    { 
      id: 7, 
      name: 'Ajay', 
      lastMessage: 'See you at 5 PM.',
      messages: [
        { id: 1, sender: 'Ajay', avatar: avatarmen, message: 'See you at 5 PM.', timestamp: '6:45 pm' },
        { id: 2, sender: 'You', avatar: userAvatar, message: 'Got it, I’ll be there!', timestamp: '6:50 pm' },
        { id: 3, sender: 'Ajay', avatar: avatarmen, message: 'Great, looking forward to it.', timestamp: '6:55 pm' },
        { id: 4, sender: 'You', avatar: userAvatar, message: 'Same here, see you soon.', timestamp: '7:00 pm' },
      ]
    },
  ];

  return (
    <AdminDrawerList>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2, // Add spacing between components
        margin: 2 // Optional margin for better overall spacing
      }}>
        {/* Chat List Column */}
        <Box sx={{ flex: 8 }}>
          <ChatList users={users} onChatClick={handleChatClick} />
        </Box>

        {/* Chat Details Column */}
        <Box sx={{ flex: 20 }}>
          {selectedChat ? (
            <ChatDetails users={users} selectedChat={selectedChat} chatMessages={selectedChat.messages} />
          ) : (
            <div>Select a chat to view messages</div>
          )}
        </Box>
      </Box>
    </AdminDrawerList>
  );
};

export default Chat;
