import React from 'react';
import {
    Paper, Avatar, Box, Typography, Button, IconButton,
} from '@mui/material';
import avatarmen from '../../assets/avatarmen.jpg';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';

const JobSeekerBio = () => {
    return (
        <Paper
            elevation={0}
            sx={{
                maxWidth: 430,
                padding: 2,
                borderRadius: "8px",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
                <Avatar src={avatarmen} alt="profile" sx={{ width: 84, height: 84 }} />
                <Typography sx={{ marginTop: 1 }} variant="h6">Pavan</Typography>
                <Typography variant="body2" color="textSecondary">Software Engineer</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                <Button variant='contained'>
                    <Typography variant='body2' sx={{ textTransform: 'none', fontWeight: "bold" }}>Follow</Typography></Button>
                <Button variant='outlined'><Typography sx={{ textTransform: 'none', fontWeight: "bold" }} variant='body2'>Message</Typography></Button>
            </Box>
            <Box sx={{ paddingInline: 1, mt: 2 }}>
                <Typography variant='body1' fontWeight={1000} fontSize='13px'>About me:</Typography>
                <Typography variant="caption" sx={{ color: '#a1a4b5' }} >Hi I'm Nil Yeager,has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.
                </Typography>
                <Typography variant="body1" fontWeight={1000} fontSize="13px" sx={{ mt: 2 }}>
                    Full Name:
                    <Typography component="span" variant="caption" sx={{ color: '#a1a4b5', marginLeft: 1 }}>
                        Pavan S
                    </Typography>
                </Typography>

                <Typography variant="body1" fontWeight={1000} fontSize="13px">
                    Contact:
                    <Typography component="span" variant="caption" sx={{ color: '#a1a4b5', marginLeft: 1 }}>
                        (123) 123-1234
                    </Typography>
                </Typography>

                <Typography variant="body1" fontWeight={1000} fontSize="13px">
                    Email:
                    <Typography component="span" variant="caption" sx={{ color: '#a1a4b5', marginLeft: 1 }}>
                        user@email.domain
                    </Typography>
                </Typography>

                <Typography variant="body1" fontWeight={1000} fontSize="13px">
                    Location:
                    <Typography component="span" variant="caption" sx={{ color: '#a1a4b5', marginLeft: 1 }}>
                        Bengaluru
                    </Typography>
                </Typography>

            </Box>
            <Box display="flex" justifyContent="center" sx={{ gap:2,mt:2}}>
                <IconButton sx={{ color: "#DB4437" }}>
                    <GoogleIcon />
                </IconButton>
                <IconButton sx={{ color: "#1DA1F2" }}>
                    <TwitterIcon />
                </IconButton>
                <IconButton sx={{ color: "#4267B2" }}>
                    <FacebookIcon />
                </IconButton>
                <IconButton sx={{ color: "#C13584" }}>
                    <InstagramIcon />
                </IconButton>
            </Box>
        </Paper>
    );
};

export default JobSeekerBio;
