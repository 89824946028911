import React from 'react';
import { Box } from '@mui/material';
import JobOverview from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobOverView';
import JobDetailsCard from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobDetaillsCard';
import JobVideoCards from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobVideoCards';

const EmployerJobStats = () => {
  return (
    <><Box  sx={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2, // Add spacing between components
      margin: 2 // Optional margin for better overall spacing
    }}>
      <JobVideoCards />
    </Box><Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2, // Add spacing between components
        margin: 2 // Optional margin for better overall spacing
      }}
    >

        {/* JobOverview takes 60% of the width */}
        <Box sx={{ flex: 10 }}>
          <JobOverview />
        </Box>

        {/* JobDetailsCard takes 40% of the width */}
        <Box sx={{ flex: 4 }}>
          <JobDetailsCard />
        </Box>
      </Box></>
  );
}

export default EmployerJobStats;
