import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from '../Tables/SeekersTable';
import { useNavigate } from 'react-router-dom';


const rowsData = [
    {
        id: 1,
        name: 'Amit Sharma',
        email: 'amit.sharma@example.com',
        phone: '9876543210',
        currentCTC: '8 LPA',
        expectedCTC: '10 LPA',
        designation: 'Software Engineer',
        experience: 5,
        location: 'Bengaluru',
        skills: ['JavaScript', 'React', 'Node.js'],
    },
    {
        id: 2,
        name: 'Neha Verma',
        email: 'neha.verma@example.com',
        phone: '9123456780',
        currentCTC: '5 LPA',
        expectedCTC: '7 LPA',
        designation: 'Frontend Developer',
        experience: 3,
        location: 'Pune',
        skills: ['HTML', 'CSS', 'JavaScript'],
    },
    {
        id: 3,
        name: 'Rohan Gupta',
        email: 'rohan.gupta@example.com',
        phone: '9765432109',
        currentCTC: '15 LPA',
        expectedCTC: '18 LPA',
        designation: 'Project Manager',
        experience: 10,
        location: 'Mumbai',
        skills: ['Leadership', 'Agile Methodologies', 'Team Management'],
    },
    {
        id: 4,
        name: 'Priya Singh',
        email: 'priya.singh@example.com',
        phone: '9654321078',
        currentCTC: '12 LPA',
        expectedCTC: '15 LPA',
        designation: 'Quality Analyst',
        experience: 7,
        location: 'Noida',
        skills: ['Testing', 'Automation', 'Selenium'],
    },
    {
        id: 5,
        name: 'Vikram Desai',
        email: 'vikram.desai@example.com',
        phone: '9543210768',
        currentCTC: '20 LPA',
        expectedCTC: '25 LPA',
        designation: 'Product Manager',
        experience: 8,
        location: 'Hyderabad',
        skills: ['Product Design', 'Strategy', 'Market Research'],
    },
];


export default function JobSeekerTable() {
    const navigate = useNavigate();


    const handleClick = (path) => {
        navigate(path)
    }

    const columns = [
        {
            field: 'name', headerName: 'Name', minWidth: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/jobseeker/personalProfile')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'phone', headerName: 'Phone Number', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'currentCTC', headerName: 'Current CTC', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'expectedCTC', headerName: 'Expected CTC', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'designation', headerName: 'Current Designation', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'experience', headerName: 'Experience (Years)', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'location', headerName: 'Location', minWidth: 200, headerClassName: 'data-grid-header' },
        {
            field: 'skills',
            headerName: 'Skills',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div>
                    {params.value.join(', ')}
                </div>
            ),
        },
    ];


    return (
        <><Box sx={{ display: 'flex', justifyContent: 'center', }}><SeekersTable columns={columns} rows={rowsData} /></Box></>

    )
}
