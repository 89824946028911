import React from 'react';
import { Box, Typography, Card, CardMedia, IconButton } from '@mui/material';
import { FileDownload as FileDownloadIcon, PlayCircle as PlayCircleIcon } from '@mui/icons-material';
import profilePhoto from '../../assets/avatarmen.jpg';
import resume from '../../assets/docs/resume.pdf'; // Ensure the path is correct
import jobseekerVideo from '../../assets/videos/bussinessVideo.webm';
import audioFile from '../../assets/audio/introduction.mp3';

const JobSeekerResume = () => {
  const fileLink = resume;
  const fileName = 'Resume.pdf';

  const items = [
    {
      title: 'Profile Photo',
      content: (
        <CardMedia
          component="img"
          src={profilePhoto}
          alt="Jobseeker Profile"
          sx={{
            borderRadius: '8px',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ),
    },
    {
      title: 'Resume / CV',
      content: (
        <Box>
          <Typography variant="body2" sx={{ marginBottom: 1, textAlign: 'center' }}>
            {fileName}
          </Typography>
          <Box sx={{ textAlign: 'right', marginBottom: 2 }}>
            <IconButton
              component="a"
              href={fileLink}
              download
              sx={{ color: 'green' }}
              title="Download Resume"
            >
              <FileDownloadIcon />
            </IconButton>
          </Box>
          <iframe
            src={fileLink}
            title="Resume PDF"
            style={{
              width: '100%',
              height: '80%',
              borderRadius: '8px',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      ),
    },
   
    {
      title: 'Audio Clip',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <PlayCircleIcon sx={{ fontSize: 40, color: '#1976d2', marginBottom: 1 }} />
          <audio controls style={{ width: '100%' }}>
            <source src={audioFile} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
        </Box>
      ),
    },
    {
      title: 'Introduction Video',
      content: (
        <video
          controls
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
          }}
        >
          <source src={jobseekerVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 3, textAlign: 'center' }}>
        Jobseeker Portfolio
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 3,
        }}
      >
        {items.map((item, index) => (
          <Card
            key={index}
            sx={{
              padding: 2,
              borderRadius: '8px',
              border: '1px solid #ddd',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '350px',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, textAlign: 'center' }}>
              {item.title}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>{item.content}</Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default JobSeekerResume;
