import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadTracker = () => {

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('uploaded');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // This will make it take full height of the parent container
        textAlign: 'center', // Center align text inside the box
        padding: 3,
      }}
    >
      {/* Title */}
      <Typography variant="h6" gutterBottom>
        Upload your Resource Tracker here 👇
      </Typography>

      {/* Instructions */}
      <Typography variant="body2" sx={{ marginBottom: 2, color: 'gray' }}>
        Click or drag file to this area to upload
      </Typography>

      <Typography variant="body2" sx={{ marginBottom: 2, color: 'gray' }}>
        Upload one file at a time.
      </Typography>

      {/* Upload Button */}
      <Box
        sx={{
          border: '1px dashed #ccc',
          borderRadius: 2,
          padding: 2,
          textAlign: 'center',
          width: '100%', // Optional to make the box stretch fully
          maxWidth: 400, // Optional: Limits the max width of the upload area
        }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<UploadFileIcon />}
          fullWidth // Optional to make the button full width
        >
          Upload .xls
          <input
            type="file"
            hidden
            accept=".xls"
            onChange={handleFileUpload}
          />
        </Button>
        <Typography variant="body2" sx={{ marginTop: 1, color: 'gray' }}>
          Supported file type: .xls
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadTracker;
