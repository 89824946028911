import React from 'react';
import { Box, Typography, Button, Grid, Paper, useTheme, useMediaQuery, Divider } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import JobDescription from './JobDescription';

const JobOverview = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: 'Clicks',
                data: [5, 10, 27, 36, 18, 12, 9],
                borderColor: 'purple',
                backgroundColor: 'rgba(128, 0, 128, 0.1)',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Paper elevation={0} sx={{ padding: 3, borderRadius: 2 }}>
            <Box>
                {/* Job Title Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Box>
                        <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">
                            Web Developer
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Vacanzi Pvt Limited
                        </Typography>
                    </Box>
                    <Button variant="outlined" size="small" sx={{
                        textTransform: "none",
                        color: "green",
                        borderColor: "green",
                        borderRadius: "6px",
                        mb: 2,
                    }} >
                        Sponsor this job
                    </Button>
                </Box>

                {/* Graph Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mt: 2,
                    }}
                >
                    {/* Chart Container */}
                    <Box sx={{ flex: 2.5, height: isMobile ? 300 : 250, mb: isMobile ? 2 : 4 }}>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                            Clicks
                        </Typography>
                        <Box sx={{ height: '100%' }}>
                            <Line data={data} options={options} />
                        </Box>
                    </Box>

                    {/* Stats and Actions Container */}
                    <Box sx={{ flex: 1.5, ml: isMobile ? 0 : 2, textAlign: isMobile ? 'center' : 'left' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Click This Week
                        </Typography>
                        <Typography variant="h4" color="primary" fontWeight="bold">
                            122
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            sx={{
                                mb: 2,
                                textTransform: 'none',
                                width: '100%',
                            }}
                        >
                            Sponsor Job for More Clicks
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                textTransform: 'none',
                                borderRadius: '6px',
                                width: '100%',
                            }}
                            color="warning"
                        >
                            Improve Job Description
                        </Button>
                    </Box>
                </Box>

                   {/* Divider (Optional for spacing on desktop) */}
                   {!isMobile && <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}



                {/* Candidates Section */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Candidates
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Awaiting Review
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    41
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Total (Add Rejected)
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    51
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2" color="text.secondary" textAlign="right" mt={1}>
                        0 Rejected
                    </Typography>
                    <JobDescription />
                </Box>
            </Box>
        </Paper>
    );
};

export default JobOverview;
