import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from '../Tables/SeekersTable';
import { useNavigate } from 'react-router-dom';

const rowsData = [
    {
        id: 1,
        name: 'Amit Sharma',
        email: 'amit.sharma@example.com',
        phone: '9876543210',
        query: 'Unable to update resume',
        status: 'Pending',
        priority: 'High',
        submittedOn: '2024-12-01',
    },
    {
        id: 2,
        name: 'Neha Verma',
        email: 'neha.verma@example.com',
        phone: '9123456780',
        query: 'Issue with application tracking',
        status: 'Resolved',
        priority: 'Medium',
        submittedOn: '2024-12-05',
    },
    {
        id: 3,
        name: 'Rohan Gupta',
        email: 'rohan.gupta@example.com',
        phone: '9765432109',
        query: 'Payment not reflecting in dashboard',
        status: 'In Progress',
        priority: 'High',
        submittedOn: '2024-12-07',
    },
    {
        id: 4,
        name: 'Priya Singh',
        email: 'priya.singh@example.com',
        phone: '9654321078',
        query: 'Unable to reset password',
        status: 'Pending',
        priority: 'Low',
        submittedOn: '2024-12-09',
    },
    {
        id: 5,
        name: 'Vikram Desai',
        email: 'vikram.desai@example.com',
        phone: '9543210768',
        query: 'Account verification issue',
        status: 'Resolved',
        priority: 'Medium',
        submittedOn: '2024-12-10',
    },
];

export default function JobSeekerSupportTable() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/support/chat')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'phone', headerName: 'Phone Number', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'query', headerName: 'Query', minWidth: 300, headerClassName: 'data-grid-header' },
        { field: 'status', headerName: 'Status', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'priority', headerName: 'Priority', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'submittedOn', headerName: 'Submitted On', minWidth: 200, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
