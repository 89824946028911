import React from 'react';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
import JobCard from '../../../components/Jobs/JobCard';
import { Grid, Box } from '@mui/material';


const EmployerNonITJobPosted = () => {
  return (
    <AdminDrawerList>
      <Grid container spacing={3} justifyContent="center">
        {/* Repeat JobCard 3 times */}
        {[1, 2, 3].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%', // Ensures equal height
              }}
            >
              <JobCard />
            </Box>
          </Grid>
        ))}
      </Grid>
    </AdminDrawerList>
  );
};

export default EmployerNonITJobPosted;
