import React, { useState } from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme, Breadcrumbs, Link, IconButton, Typography } from '@mui/material';
import { Home as HomeIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import NavBar from './NavBar';

const AdminDrawerList = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Access the current location

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#0e0593',
      },
      secondary: {
        main: '#0E0E23',
      },
    },
  });

  const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);
  const toggleDrawer = () => setIsDrawerOpen((prev) => !prev);

  const handleBackButton = () => {
    navigate(-1); // Go back to the previous page
  };

  // Function to format the path (e.g., '/home/section' to 'Home / Section')
  const getBreadcrumbText = (path) => {
    const pathSegments = path.split('/').filter(Boolean);
    return pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : 'Home';
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        {/* Sidebar */}
        <SideDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />

        {/* Navbar */}
        <NavBar
          isDrawerOpen={isDrawerOpen}
          handleMenuClick={toggleDrawer}
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
        />

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: '#e0ffff',
            p: 3,
            ml: isDrawerOpen ? `${2}px` : `2px`,
            pt: 10,
            transition: (theme) =>
              theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {/* Back Button */}
            <IconButton onClick={handleBackButton} color="grey">
              <ArrowBackIcon />
            </IconButton>

            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
              <Link
                color="inherit"
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon sx={{ mr: 0.5 }} />
                <Typography variant="body2">Home</Typography>
              </Link>

              {/* Display current route name */}
              <Typography variant="body2" color="text.primary">
                {getBreadcrumbText(location.pathname)}
              </Typography>
            </Breadcrumbs>
          </Box>

          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDrawerList;
