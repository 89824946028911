import React from 'react';
import { Box, Paper } from '@mui/material';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import DataUploadTable from '../../components/Tables/DataUploadTable';
import SourcingTracker from './SourcingTracker';
import UploadTracker from '../../components/Upload/UploadTracker';

const columns = [
  { field: 'id', headerName: 'ID', minminWidth: 200 },
  { field: 'name', headerName: 'Name', minWidth: 150 },
  { field: 'email', headerName: 'Email', minWidth: 200 },
  { field: 'role', headerName: 'Role', minWidth: 200 },
  { field: 'status', headerName: 'Status', minWidth: 120 },
  { field: 'age', headerName: 'Age', minWidth: 150 },
  { field: 'department', headerName: 'Department', minWidth: 150 },
  { field: 'dateJoined', headerName: 'Date Joined', minWidth: 150 },
  { field: 'lastLogin', headerName: 'Last Login', minWidth: 150 },
  { field: 'location', headerName: 'Location', minWidth: 150 },
];

const rows = Array.from({ length: 30 }, (_, id) => ({
  id,
  name: `User ${id + 1}`,
  email: `user${id + 1}@example.com`,
  role: id % 2 === 0 ? 'Admin' : 'Editor',
  status: id % 3 === 0 ? 'Active' : 'Inactive',
  age: 20 + (id % 30), // Ages ranging from 20 to 49
  department: id % 4 === 0 ? 'IT' : id % 4 === 1 ? 'HR' : id % 4 === 2 ? 'Marketing' : 'Sales',
  dateJoined: `2023-${(id % 12) + 1}-15`, // Dates ranging from Jan to Dec 2023
  lastLogin: `2024-${((id + 1) % 12) + 1}-05`, // Dates ranging from Jan to Dec 2024
  location: id % 3 === 0 ? 'New York' : id % 3 === 1 ? 'San Francisco' : 'Chicago',
}));

const InternalDataUpload = () => {
  return (
    <AdminDrawerList>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch', // Ensures both components stretch to match height
          padding: 3,
          gap: 2, // Adds spacing between components
        }}
      >
        {/* Left Column */}
        <Box sx={{ flex: 6 }}>
          <Paper sx={{ padding: 3 }} elevation={0}>
            <SourcingTracker />
          </Paper>
        </Box>

        {/* Right Column */}
        <Box sx={{ flex: 6 }}>
          <Paper sx={{ padding: 3, height: '100%' }} elevation={0}>
            <UploadTracker />
          </Paper>
        </Box>
      </Box>

      {/* Data Upload Table */}
      <Box sx={{ flex: 1, marginTop: 3 }}>
        <DataUploadTable columns={columns} rows={rows} />
      </Box>
    </AdminDrawerList>
  );
};

export default InternalDataUpload;
