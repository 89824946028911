import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import Vacanzi from '../../assets/vacanzi.png'; // Ensure to use your company logo
import LocationOnIcon from '@mui/icons-material/LocationOn';

const SubscriptionCard = ({ handleNavigate }) => {
    return (
        <Paper elevation={0} sx={{
            width: '100%', bgcolor: 'background.paper', boxShadow: 3, borderRadius: 3, padding: '1.8rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
        }}>
            {/* Header Section with Company Logo and Name */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <img src={Vacanzi} alt="Company Logo" style={{ width: 60, marginRight: 20 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2" sx={{
                        fontWeight: 600, color: '#6A1B9A', fontFamily: 'Poppins, sans-serif', marginBottom: '0.2rem'
                    }}>
                        Vacanzi Pvt. Ltd.
                    </Typography>
                    <Typography variant="h6" sx={{
                        fontWeight: 600, color: '#6A1B9A', fontFamily: 'Poppins, sans-serif',
                    }}>
                        Premium Subscription Plan
                    </Typography>
                </Box>
            </Box>

            {/* Subscription Price */}
            <Typography variant="h5" sx={{
                color: '#51ce8a', fontWeight: 600, marginBottom: 1, fontFamily: 'Poppins, sans-serif',
            }}>
                ₹1,500 / month
            </Typography>

            {/* Subscription Validity (Left and Right) */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
                <Typography variant="body2" sx={{
                    color: '#999', fontSize: '0.8rem', fontWeight: 300, fontFamily: 'Poppins, sans-serif',
                }}>
                    Valid from: 01-01-2025
                </Typography>
                <Typography variant="body2" sx={{
                    color: '#999', fontSize: '0.8rem', fontWeight: 300, fontFamily: 'Poppins, sans-serif',
                    textAlign: 'right'
                }}>
                    Valid until: 31-12-2025
                </Typography>
            </Box>

            {/* Subscription Status and Location (Left and Right) */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
                <Typography variant="body2" sx={{
                    color: '#6A1B9A', fontSize: '0.9rem', fontWeight: 300, fontFamily: 'Poppins, sans-serif',
                }}>
                    Status: Active
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ color: '#777', fontSize: '18px' }} />
                    <Typography variant="body2" sx={{
                        color: '#777', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins, sans-serif'
                    }}>
                        Bangalore, Karnataka
                    </Typography>
                </Box>
            </Box>

            {/* Action Button */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleNavigate}
                sx={{
                    width: '100%',
                    fontSize: '14px',
                    padding: '12px 0',
                    background: '#6A1B9A',
                    textTransform: 'none',
                    boxShadow: 3,
                    '&:hover': {
                        backgroundColor: '#4b0e72', // Darker shade on hover
                    }
                }}
            >
                Manage Subscription
            </Button>
        </Paper>
    );
};

export default SubscriptionCard;
