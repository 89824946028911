import React from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { Typography, Box } from '@mui/material';

const education = [
  {
    title: 'Bachelor of Science in Computer Science',
    institution: 'University of Tech',
    year: '2015 - 2019',
    description:
      'Studied core concepts of computer science, including algorithms, data structures, programming languages, and software development. Developed a strong foundation in computer programming and system architecture.',
  },
  {
    title: 'Diploma in Web Development',
    institution: 'Tech Academy',
    year: '2013 - 2015',
    description:
      'Focused on web technologies, HTML, CSS, JavaScript, and front-end frameworks. Gained practical skills in designing responsive websites and web applications.',
  },
  {
    title: 'High School Diploma',
    institution: 'City High School',
    year: '2010 - 2013',
    description:
      'Completed high school with a focus on science subjects. Developed basic knowledge of programming and mathematics that laid the foundation for higher education in tech.',
  },
];

const JobSeekerEducation = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, display: 'flex', alignItems: 'center' }}>
        <SchoolIcon sx={{ mr: 2 }} />
        Education
      </Typography>
      <ul style={{ paddingInlineStart: '20px', margin: 0 }}>
        {education.map((item, index) => (
          <li key={index} style={{ marginBottom: '16px', listStyleType: 'disc' }}>
            <Box sx={{ paddingLeft: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {item.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {item.institution} | {item.year}
              </Typography>
              <Typography variant="caption" sx={{ color: '#a1a4b5', marginTop: 0.5, textAlign: 'justify' }}>
                {item.description}
              </Typography>
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default JobSeekerEducation;
