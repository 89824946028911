import React, { useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import JobSeekerBio from '../../components/JobSeekers/JobSeekerBio';
import JobSeekerExpierence from '../../components/JobSeekers/JobSeekerExpierence';
import JobSeekerEducation from '../../components/JobSeekers/JobSeekerEducation';
import JobSeekerResume from '../../components/JobSeekers/JobSeekerResume';

const JobSeekerPersonalProfile = () => {
    const [tabValue, setTabValue] = useState('one');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <AdminDrawerList>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                {/* Left Section: JobSeekerBio */}
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '30%' },
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <JobSeekerBio />
                </Box>

                {/* Right Section: Tabs and Content */}
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '68%' },
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            padding: 2,
                            borderRadius: '8px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* Tabs styled as buttons */}
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="white"
                            variant="fullWidth"
                            sx={{
                                '& .MuiTab-root': {
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    bgcolor: '#f5f5f5',
                                    mx: 0.5,
                                    '&.Mui-selected': {
                                        bgcolor: 'green',
                                        color:'white'
                                    },
                                },
                            }}
                        >
                            <Tab value="one" label="Expierence" />
                            <Tab value="two" label="Education" />
                            <Tab value="three" label="Resume" />
                        </Tabs>

                        {/* Tab Content */}
                        <Box sx={{ marginTop: 2 }}>
                            {tabValue === 'one' && <JobSeekerExpierence />}
                            {tabValue === 'two' && <JobSeekerEducation />}
                            {tabValue === 'three' && <JobSeekerResume />}
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </AdminDrawerList>
    );
};

export default JobSeekerPersonalProfile;
