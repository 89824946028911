import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box } from '@mui/material';

const DataUploadTable = ({ columns, rows }) => {
    return (
        <Paper elevation={0} sx={{ height: '80vh', width: '80vw' }}>
            <Box>
                <DataGrid
                    disableColumnResize={true}
                    columnHeaderHeight={60}
                    disableColumnSelector={true}
                    disableColumnSorting={false}
                    disableDensitySelector={true}
                    disableRowSelectionOnClick={true}
                    showCellVerticalBorder={true}
                    rows={rows}
                    localeText={{
                        columnMenuSortAsc: 'Sort A-Z',
                        columnMenuSortDesc: 'Sort Z-A',
                    }}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { page: 0, pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10]}
                    getRowHeight={() => 80}
                    style={{ height: '100%', width: '100%' }}
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            padding: '14px 6px',
                            borderBottom: '2px solid #ccc',
                        },
                        '& .MuiDataGrid-cell': {
                            color: '#a1a4b5',
                            textOverflow: 'clip',
                            whiteSpace: 'normal',
                            lineHeight: '1.5',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 16px',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        },
                        '& .data-grid-header': {
                            fontWeight: 'bold',
                            textAlign: 'center',
                        },
                        '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
                            visibility: 'visible',
                            width: '24px',
                            height: '24px',
                            marginLeft: '8px',
                            display: 'inline-block',
                            opacity: 1,
                            transition: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                    }}
                />
            </Box>
        </Paper>
    );
};

export default DataUploadTable;
