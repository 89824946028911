import React from 'react'
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList'
import DeletedTables from '../../../components/Tables/DeletedTables'

const DeletedEmployer = () => {
    return (
        <AdminDrawerList>
            <DeletedTables/>
        </AdminDrawerList>
    )
}

export default DeletedEmployer