import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import PromoCodesTable from '../../components/Tables/PromoCodesTable'

const PromoCodes = () => {
    const navigate = useNavigate();

    const handleAddPromoCodes = () => {
        navigate('/addPromocodes')
    }
    return (
        <AdminDrawerList>
            <Box sx={{ textAlign: 'right', }}>
                <Button onClick={handleAddPromoCodes} sx={{ mb: 2 }} variant='contained'>Add promocodes</Button>
                <PromoCodesTable />
            </Box>
        </AdminDrawerList>
    )
}

export default PromoCodes