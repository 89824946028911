import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import EmployerTable from '../../components/Tables/EmployerTable'

const EmployerList = () => {
    return (
        <AdminDrawerList>
            <EmployerTable />
        </AdminDrawerList>

    )
}

export default EmployerList