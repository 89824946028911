// CandidateTable.js
import React from 'react';
import { Button } from '@mui/material';
import DataTable from '../../Tables/DataTables';
import { Check, Close } from '@mui/icons-material'; // Import icons for the buttons

const columns = [
  { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'data-grid-header' },
  { field: 'status', headerName: 'Status', minWidth:140, headerClassName: 'data-grid-header' },
  {
    field: 'screeningQuestions',
    headerName: 'Screening Questions',
    flex: 2,
    sortable: false,
    headerClassName: 'data-grid-header',
    renderCell: (params) => (
      <div style={{ color: params.value === '1/1 Preferred Question met' ? 'green' : '',backgroundClip: params.value === '1/1 Preferred Question met' ? 'content-box' : ''  }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 2,
    headerClassName: 'data-grid-header',
  },
  {
    field: 'interested',
    headerName: 'Interested',
    type: 'boolean',
    minWidth: 150,
    headerClassName: 'data-grid-header',
    renderCell: (params) => (
      <div style={{ display: 'flex' }}>
        <Button
          variant="outlined"
          size="small"
          color='success'
          sx={{ marginRight: 1 }}
          onClick={() => handleInterestChange(params.row.id, true)} // Right button
        >
          <Check /> {/* Right icon */}
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleInterestChange(params.row.id, false)} // Wrong button
        >
          <Close /> {/* Wrong icon */}
        </Button>
      </div>
    ),
  },
  {
    field: 'interviewStatus',
    headerName: 'Interview Status',
    flex: 2,
    headerClassName: 'data-grid-header',
    renderCell: (params) => (
      <>
        <Button
          variant="outlined"
          size="small"
          color='success'
          sx={{ marginRight: 1 }}
          onClick={() => handleSave(params.row.id)}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const rowsData = [
  { id: 1, name: 'Jon Snow', status: 'Awaiting Review', screeningQuestions: '1/1 Preferred Question met', date: '2024-12-01', interested: true, interviewStatus: 'Scheduled' },
  { id: 2, name: 'Arya Stark', status: 'Contacting', screeningQuestions: '0/1 Preferred Question met', date: '2024-12-02', interested: false, interviewStatus: 'Not Scheduled' },
  { id: 3, name: 'Cersei Lannister', status: 'Awaiting Review', screeningQuestions: '1/1 Preferred Question met', date: '2024-12-03', interested: true, interviewStatus: 'Completed' },
  { id: 4, name: 'Jaime Lannister', status: 'Contacting', screeningQuestions: '0/1 Preferred Question met', date: '2024-12-04', interested: false, interviewStatus: 'Hired' },
  { id: 5, name: 'Daenerys Targaryen', status: 'Awaiting Review', screeningQuestions: '1/1 Preferred Question met', date: '2024-12-05', interested: true, interviewStatus: 'Scheduled' },
];

const handleSave = (id) => {
  console.log(`Save clicked for row with id: ${id}`);
};

const handleDelete = (id) => {
  console.log(`Delete clicked for row with id: ${id}`);
};

const handleInterestChange = (id, isInterested) => {
  // Handle the update to the interested field based on the id and new value
  console.log(`Interest status for row ${id} set to: ${isInterested ? 'Right' : 'Wrong'}`);

  // You can update the row's state here or trigger a state change for re-render
};

export default function CandidateTable() {
  return <DataTable columns={columns} rows={rowsData} />;
}
