import React from 'react';
import { Paper, Box, TextField, List, ListItem, ListItemText, Avatar, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import avatarmen from '../../assets/avatarmen.jpg';
import userAvatar from '../../assets/man.png'; // Avatar for the user sending messages

const ChatList = ({ onChatClick, users }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: 430,
        padding: 2,
        borderRadius: "8px",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {/* Search Bar */}
      <TextField
        placeholder="Search..."
        variant="standard"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          disableUnderline: true, // Removes the underline
        }}
        sx={{
          marginBottom: 2,
          backgroundColor: 'transparent', // Sets background to white
          border: '1px solid #ccc',
          borderRadius: '14px',
          padding: '4px 8px', // Adds internal spacing
        }}
      />

      {/* User List */}
      <Box
        sx={{
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <List>
          {users.map((user) => (
            <ListItem
              key={user.id}
              sx={{
                cursor: 'pointer',
                "&:hover": { backgroundColor: '#f5f5f5' },
              }}
              onClick={() => onChatClick(user)}  // Pass selected user to the parent
            >
              {/* Avatar */}
              <Avatar
                sx={{ marginRight: 2 }}
                src={user.id % 2 === 0 ? avatarmen : userAvatar}
              >
                {user.name.charAt(0)}
              </Avatar>
              <ListItemText
                primary={
                  <Box sx={{ 
                    fontSize: '14px', // fs-14
                    fontWeight: 600,  // fw-600
                    marginTop: 0, 
                    marginBottom: 0,  // mt-0 mb-0
                    width: '75%',    // w-75
                    color: 'text.primary', // text-dark equivalent (default dark text)
                  }}>
                    {user.name}
                  </Box>
                }
                secondary={
                  <Box sx={{
                    fontSize: '12px', // Adjust size for secondary text if needed
                    color: 'text.secondary', // Lighter text for the last message
                  }}>
                    {user.lastMessage}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default ChatList;
