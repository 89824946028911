import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import AdminDrawerList from '../../../../components/Drawer/AdminDrawerList';
import './EmployerJobDetails.css';
import EmployerJobStats from './EmployerJobStats/EmployerJobStats';
import CandidateDetails from './CandidateDetails/CandidateDetails';

const EmployerJobDetails = () => {
    const [activeTab, setActiveTab] = useState('Jobs');

    const handleButtonClick = (tabName) => {
        setActiveTab(tabName);
    };

    // Shared button styles
    const sharedButtonStyles = {
        color: 'green',
        borderColor: 'green',
        padding: '8px 16px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'green',
            borderColor: 'green',
            color: '#fff',
        },
    };

    const postJobButtonStyles = {
        color: 'white',
        borderColor: 'white',
        backgroundColor: '#f2426d',
        padding: '8px 16px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f2426d',
            borderColor: 'white',
            color: '#fff',
        },
    };

    return (
        <AdminDrawerList>
            <Box className="employer-container">
                {/* Left-aligned buttons */}
                <Box className="button-group">
                    {['Jobs', 'Candidates', 'Messages'].map((tabName) => (
                        <Button
                            key={tabName}
                            sx={sharedButtonStyles}
                            variant="outlined"
                            className={`action-button ${activeTab === tabName ? 'active' : ''}`}
                            onClick={() => handleButtonClick(tabName)}
                        >
                            {tabName}
                        </Button>
                    ))}
                </Box>

                {/* Right-aligned button */}
                <Button sx={postJobButtonStyles} className="post-job-button">
                    Post a Job
                </Button>
            </Box>

            {/* Dynamic Content */}
            <Box>
                {activeTab === 'Jobs' && <EmployerJobStats />}
                {activeTab === 'Candidates' && <CandidateDetails />}
                {activeTab === 'Messages' && <Typography variant="body1">Messages Content</Typography>}
            </Box>
        </AdminDrawerList>
    );
};

export default EmployerJobDetails;
