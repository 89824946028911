import React from 'react';
import { Paper, Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';

const JobSeekerSubscriptionDetails = () => {
    // Mock data
    const subscriptionDetails = {
        subscriptionPlan: "Premium Plan",
        price: "₹999 / month",
        validFrom: "01-01-2025",
        validUntil: "31-12-2025",
        status: "Active",
    };

    const personalDetails = {
        name: "Pavan",
        email: "pavan@example.com",
        contact: "+91-9876543210",
        location: "Bangalore, Karnataka",
    };

    const appliedJobs = [
        { title: "Software Engineer", company: "TechCorp Pvt. Ltd.", date: "01-12-2024", status: "Under Review" },
        { title: "Frontend Developer", company: "Designify", date: "15-11-2024", status: "Shortlisted" },
        { title: "Project Manager", company: "ManageNow Inc.", date: "05-11-2024", status: "Rejected" },
    ];

    return (
        <AdminDrawerList>
            <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '1rem', maxWidth: '800px', margin: 'auto', bgcolor: 'background.paper' }}>
                {/* Subscription Details Section */}
                <Typography variant="h5" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Subscription Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif' }}>
                        Plan: {subscriptionDetails.subscriptionPlan}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Price: {subscriptionDetails.price}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Valid From: {subscriptionDetails.validFrom}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Valid Until: {subscriptionDetails.validUntil}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#6A1B9A', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Status: {subscriptionDetails.status}
                    </Typography>
                </Box>

                {/* Personal Details Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Personal Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Name: {personalDetails.name}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Email: {personalDetails.email}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Contact: {personalDetails.contact}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Location: {personalDetails.location}
                    </Typography>
                </Box>

                {/* Applied Jobs Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Applied Jobs
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <List>
                    {appliedJobs.map((job, index) => (
                        <ListItem key={index} sx={{ padding: '0.5rem 0' }}>
                            <ListItemText
                                primary={`${job.title} - ${job.company}`}
                                secondary={
                                    <>
                                        <Typography variant="body2" sx={{ display: 'block', fontFamily: 'Poppins, sans-serif' }}>
                                            Date Applied: {job.date}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                                            Status: {job.status}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </AdminDrawerList>
    );
};

export default JobSeekerSubscriptionDetails;
