// DataTable.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

const DataTable = ({ columns, rows }) => {
  return (
    <Paper elevation={1} sx={{ height: 400, width: '100%' }}>
      <DataGrid
        disableColumnResize={true}
        columnHeaderHeight={60} 
        disableColumnSelector={true}
        disableColumnSorting={false}
        disableDensitySelector={true}
        disableRowSelectionOnClick={true}
        showCellVerticalBorder={true}
        rows={rows}
        localeText={{
          columnMenuSortAsc: 'Sort A-Z', // Customize ascending sorting label
          columnMenuSortDesc: 'Sort Z-A', // Customize descending sorting label
        }}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 5 } },
        }}
        checkboxSelection
        pageSizeOptions={[5, 10]}
        getRowHeight={() => 80}  
        style={{ height: '100%', width: '100%' }}
        sx={{
          '& .MuiDataGrid-columnHeader': {
            fontWeight: 'bold', // Make header text bold
            fontSize: '14px',   // Adjust font size
            padding: '14px 6px', // Add padding to the header cells
            borderBottom: '2px solid #ccc'
          },
          '& .MuiDataGrid-cell': {
            color:'#a1a4b5',
            textOverflow: 'clip',
            whiteSpace: 'normal',
            lineHeight: '1.5',
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px', // Add padding to data cells for better spacing

          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            textAlign: 'center',
            display:'flex',
            alignItems:'center'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            textAlign: 'center',
            fontWeight: 'bold',
          },
          '& .data-grid-header': {
            fontWeight: 'bold',
            textAlign: 'center',
          },
          '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
            visibility: 'visible', // Ensure the filter icon is always visible
            width: '24px',         // Fixed width for the icon to prevent layout shift
            height: '24px',        // Fixed height for consistency
            marginLeft: '8px',     // Adjust space between the header title and the icon
            display: 'inline-block', // Display as inline block to avoid breaking layout
            opacity: 1,            // Ensure the icon is fully visible
            transition: 'none',    // Remove transition to prevent movement
          },
          '& .MuiDataGrid-columnHeader:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: Add hover effect without affecting layout
          }
        }}
      />
    </Paper>
  );
};

export default DataTable;
