import React from 'react';
import { Grid, TextField, Box, Typography, Button } from '@mui/material';

const JobApplicationForm = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Sourcing Tracker
      </Typography>
      <Grid container spacing={2}>
        {/* Full Name */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Full Name" required />
        </Grid>
        {/* Email */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Email" required type="email" />
        </Grid>
        {/* Phone */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Phone" required type="tel" />
        </Grid>
        {/* Applied Designation */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Applied Designation" />
        </Grid>
        {/* Applied Job Location */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Applied Job Location" />
        </Grid>
        {/* Current Company */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Current Company" />
        </Grid>
        {/* Current Designation */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Current Designation" />
        </Grid>
        {/* Total Experience */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Total Experience" />
        </Grid>
        {/* Current CTC */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Current CTC" />
        </Grid>
        {/* Notice Period */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Notice Period" />
        </Grid>
        {/* Experience Type */}
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth label="Experience Type" />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained">
            <Typography sx={{ textTransform:'none'}} variant="body2">Upload Candidiate</Typography>
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
};

export default JobApplicationForm;
