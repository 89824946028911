import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import JobSeekerPaymentTable from '../../components/Tables/JobSeekerPaymentTable'

const JobSeekerPayments = () => {
  return (
    <AdminDrawerList>
      <JobSeekerPaymentTable />
    </AdminDrawerList>
  )
}

export default JobSeekerPayments