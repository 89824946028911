import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from './SeekersTable';
import { useNavigate } from 'react-router-dom';

// Expanded Payment Details Data
const rowsData = [
    {
        id: 1,
        employerName: 'TechCorp Ltd',
        email: 'hr@techcorp.com',
        paymentDate: '2024-01-10',
        amountPaid: '₹50,000',
        dueAmount: '₹0',
        paymentStatus: 'Completed',
        paymentMethod: 'Credit Card',
        transactionId: 'TXN123456',
    },
    {
        id: 2,
        employerName: 'GreenField Inc',
        email: 'contact@greenfield.com',
        paymentDate: '2024-01-05',
        amountPaid: '₹30,000',
        dueAmount: '₹10,000',
        paymentStatus: 'Pending',
        paymentMethod: 'Bank Transfer',
        transactionId: 'TXN789012',
    },
    {
        id: 3,
        employerName: 'FinLogic Pvt Ltd',
        email: 'info@finlogic.com',
        paymentDate: '2024-01-12',
        amountPaid: '₹45,000',
        dueAmount: '₹5,000',
        paymentStatus: 'Pending',
        paymentMethod: 'UPI',
        transactionId: 'TXN345678',
    },
    {
        id: 4,
        employerName: 'EduCare Solutions',
        email: 'support@educare.com',
        paymentDate: '2024-01-15',
        amountPaid: '₹20,000',
        dueAmount: '₹0',
        paymentStatus: 'Completed',
        paymentMethod: 'PayPal',
        transactionId: 'TXN9101112',
    },
    {
        id: 5,
        employerName: 'SmartTech Innovations',
        email: 'info@smarttech.com',
        paymentDate: '2024-01-18',
        amountPaid: '₹60,000',
        dueAmount: '₹0',
        paymentStatus: 'Completed',
        paymentMethod: 'Credit Card',
        transactionId: 'TXN13141516',
    },
];

export default function PaymentsTable() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    // Updated Columns for Payment Details Table
    const columns = [
        {
            field: 'employerName',
            headerName: 'Employer Name',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/employer/paymentDetails')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'paymentDate', headerName: 'Payment Date', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'amountPaid', headerName: 'Amount Paid', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'dueAmount', headerName: 'Due Amount', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'paymentStatus', headerName: 'Payment Status', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'paymentMethod', headerName: 'Payment Method', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'transactionId', headerName: 'Transaction ID', minWidth: 200, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
