import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import EmployerJobPosted from '../pages/Employers/InformationTechnology/EmployerJobPosted';
import EmployerDataBase from '../pages/Employers/InformationTechnology/EmployerDataBase';
import EmployerNonITJobPosted from '../pages/Employers/NonInformationTechnology/EmployerNonITJobPosted';
import EmployerNonITDatabase from '../pages/Employers/NonInformationTechnology/EmployerNonITDatabase';
import EmployerJobDetails from '../pages/Employers/InformationTechnology/EmployerJobDetails/EmployerJobDetails';
import JobSeekers from '../pages/JobSeekers/JobSeekers';
import Support from '../pages/Support/Support';
import Chat from '../pages/Support/Chat';
import JobSeekerPersonalProfile from '../pages/JobSeekers/JobSeekerPersonalProfile';
import EmployerSubscription from '../pages/Subscription/EmployerSubscription/EmployerSubscription';
import JobSeekerSubscription from '../pages/Subscription/JobSeekerSubscription/JobSeekerSubscription';
import Login from '../pages/Auth/Login/Login';
import InternalDataUpload from '../pages/DataUpload/InternalDataUpload';
import PromoCodes from '../pages/PromoCodes/PromoCodes';
import Quotations from '../pages/Quotations/Quotations';
import DeletedEmployer from '../pages/Deleted/DeletedEmployers/DeletedEmployer';
import EmployerList from '../pages/Employers/EmployerList';
import EmployerPayments from '../pages/Payments/EmployerPayments';
import JobSeekerPayments from '../pages/Payments/JobSeekerPayments';
import AddPromoCodes from '../pages/PromoCodes/AddPromoCodes';
import EmployerSubscriptionDetails from '../pages/Subscription/EmployerSubscription/EmployerSubscriptionDetails';
import JobSeekerSubscriptionDetails from '../pages/Subscription/JobSeekerSubscription/JobSeekerSubscriptionDetails';
import JobSeekerReports from '../pages/Reports/JobSeekerReports';
import EmployerReports from '../pages/Reports/EmployerReports';


const RoutingComponent = () => {

  return (
    <Router>
      <Routes>

        {/* Login */}
        <Route path="/" element={<Login />} />

        {/* DashBoard */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Data Upload */}
        <Route path="/internal/dataUpload" element={<InternalDataUpload />} />

        {/* Employers */}
        <Route path="/employer/employersList" element={<EmployerList />} />
        <Route path="/employer/ITJobPosted" element={<EmployerJobPosted />} />
        <Route path="/employer/ITDatabase" element={<EmployerDataBase />} />
        <Route path="/employer/NonITJobPosted" element={<EmployerNonITJobPosted />} />
        <Route path="/employer/NonITDatabase" element={<EmployerNonITDatabase />} />

        <Route path="/employer/employerJobDetails" element={<EmployerJobDetails />} />


        {/* JobSeekers */}
        <Route path="/jobseekers" element={<JobSeekers />} />
        <Route path="/jobseeker/personalProfile" element={<JobSeekerPersonalProfile />} />

        {/* Subscription */}
        <Route path="/employer/subscription" element={<EmployerSubscription />} />
        <Route path="/employer/subscriptionDetails" element={<EmployerSubscriptionDetails />} />
        <Route path="/jobSeeker/subscription" element={<JobSeekerSubscription />} />
        <Route path="/jobSeeker/subscriptionDetails" element={<JobSeekerSubscriptionDetails />} />

        {/* Support */}
        <Route path="/support/supportTicket" element={<Support />} />
        <Route path="/support/chat" element={<Chat />} />

        {/* Promocodes */}
        <Route path="/promoCodes" element={<PromoCodes />} />
        <Route path="/addPromocodes" element={<AddPromoCodes />} />

        {/* Quotations */}
        <Route path="/quotations" element={<Quotations />} />

        {/* Payments */}
        <Route path="/employerPayments" element={<EmployerPayments />} />
        <Route path="/jobSeekerPayments" element={<JobSeekerPayments />} />

        {/* Reports */}
        <Route path="/reports/jobseekers" element={<JobSeekerReports />} />
        <Route path="/reports/employers" element={<EmployerReports />} />


        {/* Deleted */}
        <Route path="/deletedEmployers" element={<DeletedEmployer />} />
      </Routes>
    </Router>
  );

}

export default RoutingComponent;
