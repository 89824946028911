import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from '../Tables/SeekersTable';
import { useNavigate } from 'react-router-dom';

const rowsData = [
    {
        id: 1,
        promoCode: 'SAVE20',
        discount: '20%',
        usageLimit: 50,
        used: 30,
        validFrom: '2024-12-01',
        validUntil: '2024-12-31',
        status: 'Active',
    },
    {
        id: 2,
        promoCode: 'NEWYEAR50',
        discount: '50%',
        usageLimit: 100,
        used: 80,
        validFrom: '2024-12-20',
        validUntil: '2025-01-10',
        status: 'Active',
    },
    {
        id: 3,
        promoCode: 'WELCOME10',
        discount: '10%',
        usageLimit: 200,
        used: 150,
        validFrom: '2024-11-01',
        validUntil: '2024-12-31',
        status: 'Expired',
    },
    {
        id: 4,
        promoCode: 'FESTIVE30',
        discount: '30%',
        usageLimit: 100,
        used: 50,
        validFrom: '2024-12-15',
        validUntil: '2025-01-15',
        status: 'Active',
    },
    {
        id: 5,
        promoCode: 'SUMMER15',
        discount: '15%',
        usageLimit: 80,
        used: 20,
        validFrom: '2024-06-01',
        validUntil: '2024-06-30',
        status: 'Expired',
    },
];

export default function PromoCodesTable() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    const columns = [
        {
            field: 'promoCode',
            headerName: 'Promo Code',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'discount', headerName: 'Discount', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'usageLimit', headerName: 'Usage Limit', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'used', headerName: 'Used', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'validFrom', headerName: 'Valid From', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'validUntil', headerName: 'Valid Until', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'status', headerName: 'Status', minWidth: 150, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
