import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'

const EmployerReports = () => {
    return (
        <AdminDrawerList>
            <div>EmployerReports</div>
        </AdminDrawerList>
    )
}

export default EmployerReports