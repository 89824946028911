import React from 'react'
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList'

const EmployerNonITDatabase = () => {
    return (
        <AdminDrawerList>
            <div>EmployerNonITDatabase</div>
        </AdminDrawerList>
    )
}

export default EmployerNonITDatabase