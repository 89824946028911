import React, { useState } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const VideoSlider = ({ media = [] }) => {
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
  };

  if (media.length === 0) {
    return <Box sx={{ textAlign: "center", marginTop: 4 }}>No Media Available</Box>;
  }

  // Limit to 4 videos at max
  const displayMedia = media.slice(0, 4);

  return (
    <Box sx={{ marginTop: 2, position: "relative" }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {displayMedia.map((item, index) => (
          <Box key={index} sx={{ flex: "1 1 calc(25% - 16px)", maxWidth: "calc(25% - 16px)" }}>
            <video
              src={item.src}
              muted
              loop
              autoPlay
              style={{
                width: "100%",
                height: 230,
                objectFit: "cover",
                borderRadius: "12px",
              }}
              onClick={() => {
                setSelectedMedia(item);
                setOpen(true);
              }}
            />
          </Box>
        ))}
      </Box>

      <Box
        className="custom-prev"
        sx={{
          position: "absolute",
          top: "50%",
          left: "-20px",
          transform: "translate(0, -50%)",
          zIndex: 10,
          fontSize: "36px",
          fontWeight: "bold",
          color: "#333",
          cursor: "pointer",
          "&:hover": {
            color: "#000",
          },
        }}
      >
        &#8249;
      </Box>
      <Box
        className="custom-next"
        sx={{
          position: "absolute",
          top: "50%",
          right: "-20px",
          transform: "translate(0, -50%)",
          zIndex: 10,
          fontSize: "36px",
          fontWeight: "bold",
          color: "#333",
          cursor: "pointer",
          "&:hover": {
            color: "#000",
          },
        }}
      >
        &#8250;
      </Box>

      {/* Custom Pagination */}
      <Box
        className="custom-pagination"
        sx={{
          marginTop: 2,
          textAlign: "center",
          "& .swiper-pagination-bullet": {
            backgroundColor: "#333",
            opacity: 0.8,
            width: "14px",
            height: "14px",
            borderRadius: "50%",
            transition: "all 0.3s ease",
          },
          "& .swiper-pagination-bullet-active": {
            backgroundColor: "#000",
            opacity: 1,
          },
        }}
      />

      {/* Media Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "20%",
            maxWidth: "200px",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            overflow: "hidden",
            outline: "none",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "rgba(255, 255, 255, 0.7)",
                zIndex: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedMedia?.type === "video" ? (
              <video
                src={selectedMedia.src}
                controls
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                src={selectedMedia?.src}
                alt="Enlarged"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoSlider;
