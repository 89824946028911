import React from "react";
import VideoSlider from "../../ResusableComponents/VideoSlider";
import introductionVideo from "../../../assets/videos/video_profile.mp4";
import introduceYourself from "../../../assets/videos/introduceyourself.mp4";
import scrollVideo from "../../../assets/videos/bussinessVideo.webm";

const JobVideoCards = () => {
  const media = [
    { type: "video", src: introductionVideo },
    { type: "video", src: introduceYourself },
    { type: "video", src: scrollVideo },
    { type: "video", src: introduceYourself },
  ];

  return (
    <><VideoSlider media={media} /></>
  )
};

export default JobVideoCards;
