import React from 'react';
import { Paper, Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';

const EmployerSubscriptionDetails = () => {
    // Mock data
    const subscriptionDetails = {
        companyName: "Vacanzi Pvt. Ltd.",
        subscriptionPlan: "Premium Plan",
        price: "₹1,500 / month",
        validFrom: "01-01-2025",
        validUntil: "31-12-2025",
        status: "Active",
        location: "Bangalore, Karnataka",
    };

    const companyDetails = {
        address: "123 Business Street, Bangalore, Karnataka, 560001",
        contactNumber: "+91-9876543210",
        email: "info@vacanzi.com",
    };

    const recruiters = [
        { name: "Bharath", position: "Senior Recruiter", email: "john.doe@vacanzi.com", contact: "+91-9000000000" },
        { name: "Pavan", position: "HR Specialist", email: "jane.smith@vacanzi.com", contact: "+91-9000000001" },
    ];

    return (
        <AdminDrawerList>
            <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '1rem', maxWidth: '800px', margin: 'auto', bgcolor: 'background.paper' }}>
                {/* Subscription Details Section */}
                <Typography variant="h5" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Subscription Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif' }}>
                        Plan: {subscriptionDetails.subscriptionPlan}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Price: {subscriptionDetails.price}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Valid From: {subscriptionDetails.validFrom}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Valid Until: {subscriptionDetails.validUntil}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#6A1B9A', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Status: {subscriptionDetails.status}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#777', fontFamily: 'Poppins, sans-serif' }}>
                        Location: {subscriptionDetails.location}
                    </Typography>
                </Box>

                {/* Company Details Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Company Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Address: {companyDetails.address}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Contact Number: {companyDetails.contactNumber}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Email: {companyDetails.email}
                    </Typography>
                </Box>

                {/* Recruiters Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Recruiters
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <List>
                    {recruiters.map((recruiter, index) => (
                        <ListItem key={index} sx={{ padding: '0.5rem 0' }}>
                            <ListItemText
                                primary={`${recruiter.name} - ${recruiter.position}`}
                                secondary={
                                    <>
                                        <Typography variant="body2" sx={{ display: 'block', fontFamily: 'Poppins, sans-serif' }}>
                                            Email: {recruiter.email}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                                            Contact: {recruiter.contact}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </AdminDrawerList>
    );
};

export default EmployerSubscriptionDetails;
