import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Box,
  Badge,
  Avatar,
} from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AvatarMen from '../../assets/avatarmen.jpg';

const NavBar = ({ isDrawerOpen, handleMenuClick, toggleTheme, isDarkMode }) => {
  const [notifications] = useState(3); // Dummy notifications count

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${isDrawerOpen ? 240 : 64}px)`,
        ml: isDrawerOpen ? `240px` : `64px`,
        bgcolor: 'background.paper',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Drawer Toggle */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
          disabled
            onClick={handleMenuClick}
            sx={{
              mr: 2,
              color: 'text.primary',
              '&:hover': { bgcolor: 'action.hover' },
            }}
          >
            {/* <MenuIcon /> */}
          </IconButton>

          {/* Search Bar */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '300px',
              bgcolor: '#e0ffff',
              borderRadius: 1,
              px: 2,
              p: 0.8
            }}
          >
            <InputBase
              sx={{
                flex: 1,
                color: 'black'
              }}
              placeholder="Search..."
              inputProps={{ 'aria-label': 'search' }}
            />
            <SearchIcon sx={{ color: 'black' }} />
          </Box>
        </Box>

        {/* Right Section */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Dark Mode Toggle */}
          <IconButton onClick={toggleTheme} sx={{ mx: 1 }}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>

          {/* Notifications */}
          <IconButton sx={{ mx: 1 }}>
            <Badge badgeContent={notifications} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Avatar */}
          <IconButton sx={{ mx: 1 }}>
            <Avatar alt="User Avatar" src={AvatarMen} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
