import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import JobSeekerTable from '../../components/JobSeekers/JobSeekerTable'

const JobSeekers = () => {
    return (
        <AdminDrawerList>
            <JobSeekerTable/>
        </AdminDrawerList>
    )
}

export default JobSeekers