import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import QuotationTable from '../../components/Tables/QuotationsTable'

const Quotations = () => {
    return (
        <AdminDrawerList>
            <QuotationTable />
        </AdminDrawerList>
    )
}

export default Quotations