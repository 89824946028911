import React, { useState } from 'react';
import { Drawer, IconButton, Divider, Typography, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import Vacanzi from '../../assets/vacanzi.png';
import './SideDrawer.css'; // Import your custom CSS file

const drawerWidth = 260;

const SideDrawer = ({ isDrawerOpen, toggleDrawer }) => {
    const navigate = useNavigate();
    const [openReports, setOpenReports] = useState(false);
    const [openEmployers, setOpenEmployers] = useState(false);
    const [openPaymments, setOpenPaymments] = useState(false);
    const [openSupport, setOpenSupport] = useState(false);
    const [openSubscription, setOpenSubscription] = useState(false);
    const [openContentManagement, setOpenContentManagement] = useState(false);
    const [openEmployerIT, setOpenEmployerIT] = useState(false); // Toggles IT submenu
    const [openEmployerNonIT, setOpenEmployerNonIT] = useState(false); // Toggles NON IT submenu
    const [selectedItem, setSelectedItem] = useState(null);

    // Handle toggling for each section individually
    const handleEmployersToggle = () => {
        setOpenEmployers(!openEmployers);
    };
    const handleEmployerSubscription = () => setOpenSubscription(!openSubscription)
    const handlePaymments = () => setOpenPaymments(!openPaymments);
    const handleSupport = () => setOpenSupport(!openSupport);
    const handleReports = () => setOpenReports(!openReports);
    const handleContentManagement = () => setOpenContentManagement(!openContentManagement);

    const handleITToggle = () => {
        setOpenEmployerIT(!openEmployerIT);
    };

    const handleNonITToggle = () => {
        setOpenEmployerNonIT(!openEmployerNonIT);
    };


    const handleNavigation = (path) => {
        navigate(path); // Navigate without closing menus
    };

    const handleItemClick = (item) => {
        setSelectedItem(item); // Set the selected item to change its color
    };

    return (
        <Drawer
            variant="permanent"
            open={isDrawerOpen}
            sx={{
                width: isDrawerOpen ? drawerWidth : 64,
                flexShrink: 0,
                height: '100vh',
                whiteSpace: 'nowrap',
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                [`& .MuiDrawer-paper`]: {
                    width: isDrawerOpen ? drawerWidth : 64,
                    boxSizing: 'border-box',
                    overflowX: 'hidden',
                    bgcolor: '#121212',
                    color: 'white',
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                },
            }}
        >
            <IconButton
                onClick={toggleDrawer}
                sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
            >
                {isDrawerOpen ? (
                    <Typography
                        variant="h6"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1.50rem',
                            fontWeight: 'bold',
                            height: '80px',
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    >
                        <img
                            src={Vacanzi}
                            height={50}
                            width={50}
                            alt="vacanzi"
                            style={{ marginRight: '2px' }}
                        />
                        Vacanzi Admin
                    </Typography>
                ) : (
                    <img src={Vacanzi} height={50} width={50} alt="vacanzi" />
                )}
            </IconButton>

            <Divider />

            <ul className="side-drawer-list">
                {/* Dashboard Item */}
                <li className="list-item">
                    <DashboardIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span onClick={() => handleNavigation('/dashboard')}>Dashboard</span>}
                </li>

                <li className="list-item" onClick={() => handleNavigation('/internal/dataUpload')}>
                    <UploadFileIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Internal Data Upload</span>}
                </li>
                {/* Employers Menu */}
                <li className="list-item" onClick={handleEmployersToggle}>
                    <BadgeIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Employers</span>}
                    {isDrawerOpen && (
                        openEmployers ? (
                            <ExpandLessIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        ) : (
                            <ExpandMoreIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        )
                    )}
                </li>

                {/* Submenu for IT and NON IT */}
                {isDrawerOpen && (
                    <Collapse in={openEmployers} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                        <ul className="sub-list">
                            <li className="sub-list-item" onClick={() => handleNavigation('/employer/employersList')}>Employer List</li>
                            {/* IT Section */}
                            <li className="sub-list-item" onClick={handleITToggle}>
                                IT
                                {openEmployerIT ? (
                                    <ExpandLessIcon fontSize="small" className="expand-icon" />
                                ) : (
                                    <ExpandMoreIcon fontSize="small" className="expand-icon" />
                                )}
                            </li>
                            <Collapse in={openEmployerIT} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                                <ul className="nested-sub-list">
                                    <li
                                        className="nested-sub-list-item"
                                        onClick={() => {
                                            handleNavigation('/employer/ITJobPosted');
                                            setOpenEmployerIT(openEmployerIT);  // Ensure IT section stays open after the first click
                                            handleItemClick('ITJobPosted'); // Track the clicked item

                                        }}
                                        style={{
                                            color: selectedItem === 'ITJobPosted' ? 'green' : '#b0b0b0',
                                        }}
                                    >
                                        Job Posted
                                    </li>
                                    <li
                                        className="nested-sub-list-item"
                                        onClick={() => {
                                            handleNavigation('/employer/ITDatabase');
                                            handleItemClick('ITDatabase'); // Track the clicked item
                                            setOpenEmployerIT(openEmployerIT);  // Ensure IT section stays open after the first click

                                        }}
                                        style={{
                                            color: selectedItem === 'ITDatabase' ? 'green' : '#b0b0b0',
                                        }}
                                    >
                                        Database
                                    </li>
                                </ul>
                            </Collapse>


                            {/* NON IT Section */}
                            <li className="sub-list-item" onClick={handleNonITToggle}>
                                NON IT
                                {openEmployerNonIT ? (
                                    <ExpandLessIcon fontSize="small" className="expand-icon" />
                                ) : (
                                    <ExpandMoreIcon fontSize="small" className="expand-icon" />
                                )}
                            </li>
                            <Collapse in={openEmployerNonIT} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                                <ul className="nested-sub-list">
                                    <li
                                        className="nested-sub-list-item"
                                        onClick={() => {
                                            handleNavigation('/employer/NonITJobPosted');
                                        }}
                                    >
                                        Job Posted
                                    </li>
                                    <li
                                        className="nested-sub-list-item"
                                        onClick={() => {
                                            handleNavigation('/employer/NonITDatabase');
                                        }}
                                    >
                                        Database
                                    </li>
                                </ul>
                            </Collapse>
                        </ul>
                    </Collapse>
                )}

                {/* JobSeekers Item */}
                <li className="list-item" onClick={() => {
                    handleNavigation('/jobseekers');
                }}>
                    <BusinessIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>JobSeekers</span>}
                </li>

                <li className="list-item" onClick={handleEmployerSubscription}>
                    <AccountBalanceWalletIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Subscription</span>}
                    {isDrawerOpen && (
                        openSubscription ? (
                            <ExpandLessIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        ) : (
                            <ExpandMoreIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        )
                    )}
                </li>
                {isDrawerOpen && (
                    <Collapse in={openSubscription} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                        <ul className="sub-list">
                            {/* Employer Subscription Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/employer/subscription');
                            }}>
                                Employer Subscriptions
                            </li>
                            {/* JobSeeker Subscription Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/jobSeeker/subscription');
                            }}>
                                JobSeeker Subscriptions
                            </li>
                        </ul>
                    </Collapse>
                )}

                <li className="list-item" onClick={handlePaymments}>
                    <CurrencyRupeeIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Payments</span>}
                    {isDrawerOpen && (
                        openPaymments ? (
                            <ExpandLessIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        ) : (
                            <ExpandMoreIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        )
                    )}
                </li>
                {isDrawerOpen && (
                    <Collapse in={openPaymments} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                        <ul className="sub-list">
                            {/* IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/employerPayments');
                            }}>
                                Employer Payments
                            </li>
                            {/* NON IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/jobSeekerPayments');
                            }}>
                                JobSeeker Payments
                            </li>
                        </ul>
                    </Collapse>
                )}
                <li className="list-item" onClick={handleSupport}>
                    <SupportAgentIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Support</span>}
                    {isDrawerOpen && (
                        openSupport ? (
                            <ExpandLessIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        ) : (
                            <ExpandMoreIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        )
                    )}
                </li>
                {/* Submenu for IT and NON IT */}
                {isDrawerOpen && (
                    <Collapse in={openSupport} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                        <ul className="sub-list">
                            {/* IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/support/supportTicket');
                            }}>
                                Support Ticket
                            </li>
                            {/* NON IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/support/chat');
                            }}>
                                Chat
                            </li>
                        </ul>
                    </Collapse>
                )}
                <li className="list-item" onClick={handleReports}>
                    <BarChartIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Reports</span>}
                    {isDrawerOpen && (
                        openReports ? (
                            <ExpandLessIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        ) : (
                            <ExpandMoreIcon fontSize="small" sx={{ color: 'white' }} className="expand-icon" />
                        )
                    )}
                </li>
                {isDrawerOpen && (
                    <Collapse in={openReports} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
                        <ul className="sub-list">
                            {/* IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/reports/jobseekers');
                            }}>
                               JobSeeker Reports
                            </li>
                            {/* NON IT Section */}
                            <li className="sub-list-item" onClick={() => {
                                handleNavigation('/reports/employers');
                            }}>
                               Employer Reports
                            </li>
                        </ul>
                    </Collapse>
                )}

                <li className="list-item" onClick={() => {
                    handleNavigation('/promoCodes');
                }}>
                    <LocalOfferIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Promocodes</span>}
                </li>
                <li className="list-item" onClick={() => {
                    handleNavigation('/quotations');
                }}>
                    <DescriptionIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Quotations</span>}
                </li>
                <li className="list-item" onClick={handleContentManagement}>
                    <SettingsApplicationsIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Content Management</span>}
                </li>
                <li className="list-item" onClick={() => {
                    handleNavigation('/deletedEmployers');
                }}>
                    <DeleteIcon className="icon" fontSize="small" />
                    {isDrawerOpen && <span>Deleted</span>}
                </li>
            </ul>
        </Drawer>
    );
};

export default SideDrawer;
