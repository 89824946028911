import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import SupportTable from '../../components/Tables/SupportTable'

const Support = () => {
  return (
    <AdminDrawerList>
      <SupportTable />
    </AdminDrawerList>
  )
}

export default Support