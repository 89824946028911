import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from '../Tables/SeekersTable';
import { useNavigate } from 'react-router-dom';

const rowsData = [
    {
        id: 1,
        employerName: 'Tech Solutions Inc.',
        employerEmail: 'hr@techsolutions.com',
        contactNumber: '9876543210',
        reason: 'Violation of terms',
        deletedOn: '2024-12-01',
    },
    {
        id: 2,
        employerName: 'Creative Minds Pvt Ltd.',
        employerEmail: 'contact@creativeminds.com',
        contactNumber: '9123456780',
        reason: 'Repeated complaints from job seekers',
        deletedOn: '2024-12-03',
    },
    {
        id: 3,
        employerName: 'Global Tech Corp.',
        employerEmail: 'support@globaltech.com',
        contactNumber: '9765432109',
        reason: 'Fraudulent job postings',
        deletedOn: '2024-12-05',
    },
    {
        id: 4,
        employerName: 'Reliable Systems Ltd.',
        employerEmail: 'info@reliablesystems.com',
        contactNumber: '9654321078',
        reason: 'Non-compliance with platform policies',
        deletedOn: '2024-12-07',
    },
    {
        id: 5,
        employerName: 'Visionary Products LLC.',
        employerEmail: 'jobs@visionaryproducts.com',
        contactNumber: '9543210768',
        reason: 'Misleading job advertisements',
        deletedOn: '2024-12-10',
    },
];

export default function DeletedTables() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    const columns = [
        {
            field: 'employerName',
            headerName: 'Employer Name',
            minWidth: 250,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/')}>  
                    {params.value}
                </div>
            ),
        },
        { field: 'employerEmail', headerName: 'Employer Email', minWidth: 250, headerClassName: 'data-grid-header' },
        { field: 'contactNumber', headerName: 'Contact Number', minWidth: 250, headerClassName: 'data-grid-header' },
        { field: 'reason', headerName: 'Reason for Deletion', minWidth: 300, headerClassName: 'data-grid-header' },
        { field: 'deletedOn', headerName: 'Deleted On', minWidth: 250, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
