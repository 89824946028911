import React from 'react'
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'
import PaymentsTable from '../../components/Tables/PaymentsTable'

const EmployerPayments = () => {
    return (
        <AdminDrawerList>
            <PaymentsTable />
        </AdminDrawerList>
    )
}

export default EmployerPayments