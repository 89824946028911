import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from './SeekersTable';
import { useNavigate } from 'react-router-dom';


const rowsData = [
    {
        id: 1,
        name: 'TechCorp Ltd',
        email: 'hr@techcorp.com',
        phone: '9876543210',
        industry: 'IT Services',
        size: '500-1000 employees',
        location: 'Bengaluru',
        openings: 5,
        requirements: ['React Developer', 'Backend Developer'],
    },
    {
        id: 2,
        name: 'GreenField Inc',
        email: 'contact@greenfield.com',
        phone: '9123456780',
        industry: 'Renewable Energy',
        size: '200-500 employees',
        location: 'Pune',
        openings: 3,
        requirements: ['Electrical Engineer', 'Project Manager'],
    },
    {
        id: 3,
        name: 'FinLogic Pvt Ltd',
        email: 'info@finlogic.com',
        phone: '9765432109',
        industry: 'Finance',
        size: '50-200 employees',
        location: 'Mumbai',
        openings: 2,
        requirements: ['Data Scientist', 'AI Engineer'],
    },
];



export default function EmployerTable() {
    const navigate = useNavigate();


    const handleClick = (path) => {
        navigate(path)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Company Name',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/employer/companyProfile')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'phone', headerName: 'Phone Number', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'industry', headerName: 'Industry', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'size', headerName: 'Company Size', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'location', headerName: 'Location', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'openings', headerName: 'Openings', minWidth: 200, headerClassName: 'data-grid-header' },
        {
            field: 'requirements',
            headerName: 'Requirements',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div>
                    {params.value.join(', ')}
                </div>
            ),
        },
    ];
    


    return (
        <><Box sx={{ display: 'flex', justifyContent: 'center', }}><SeekersTable columns={columns} rows={rowsData} /></Box></>

    )
}
