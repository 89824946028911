import React from 'react';
import { Box } from '@mui/material';
import CandidiateJobFilters from '../../../../../components/EmployerJobDetailsComponents/CandidateDetails/CandidiateJobFilters';
import CandidateTable from '../../../../../components/EmployerJobDetailsComponents/CandidateDetails/CandidiateTable';

const CandidateDetails = () => {
    return (

        <Box
            sx={{
                gap: 1, // Add spacing between components
                margin: 2 // Optional margin for better overall spacing
            }}
        ><CandidiateJobFilters /><CandidateTable /></Box>

    );
}

export default CandidateDetails;
