import React from 'react';
import { Box, Typography, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';

// Define the styles using styled-components (MUI's styled API)
const StyledTypography = styled(Typography)({
    color: '#a1a4b5',
    marginBottom: '0.1rem',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    textAlign: 'justify',
    fontFamily: 'Poppins, sans-serif',
});

const JobDescription = () => {
    return (
        <Paper elevation={0} sx={{ padding: 1, borderRadius: 2 }}>
            <Box>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={2}>
                    {/* Job Description */}
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            Job Description:
                        </Typography>
                        <StyledTypography variant="body1" component="div">
                            <ul>
                                <li>Perform day-to-day tasks.</li>
                                <li>Office daily work.</li>
                                <li>Maintain records of the office.</li>
                                <li>Other office activity.</li>
                                <li>Manage clients.</li>
                            </ul>
                        </StyledTypography>
                    </Grid>

                    {/* Expected Start Date */}
                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Expected Start Date: 23/11/2020
                        </StyledTypography>
                    </Grid>

                    {/* Job Type */}
                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Job Types: Full-time, Fresher
                        </StyledTypography>
                    </Grid>

                    {/* Salary */}
                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Salary: From $8,000.00 per month
                        </StyledTypography>
                    </Grid>

                    <Divider sx={{ marginBottom: 1, marginTop: 1, width: '100%' }} />

                    {/* Contact Information */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Speak with the employer:
                        </Typography>
                        <StyledTypography variant="body1">
                            +1 123 456 7890
                        </StyledTypography>
                    </Grid>

                    {/* Application Questions */}
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            Application Questions:
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#a1a4b5', mb: 1 }}>
                            You have requested that Indeed ask candidates the following questions:
                        </Typography>
                        <StyledTypography variant="body1" component="div">
                            <ul>
                                <li>What is the highest level of education you have completed?</li>
                            </ul>
                        </StyledTypography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default JobDescription;
