import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
import { Grid, Box } from '@mui/material';
import SubscriptionCard from '../../../components/ResusableComponents/subscroptionCard';

const EmployerJobPosted = () => {

const navigate = useNavigate();


const handleNavigate = () => {
  navigate('/employer/subscriptionDetails')
}
  return (
    <AdminDrawerList>
      <Grid container spacing={3} justifyContent="center">
        {/* Repeat JobCard 3 times */}
        {[1, 2, 3].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%', // Ensures equal height
              }}
            >
              <SubscriptionCard handleNavigate={handleNavigate}/>
            </Box>
          </Grid>
        ))}
      </Grid>
    </AdminDrawerList>
  );
};

export default EmployerJobPosted;
