import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from '../Tables/SeekersTable';
import { useNavigate } from 'react-router-dom';

const rowsData = [
    {
        id: 1,
        quotationId: 'Q12345',
        jobTitle: 'Software Engineer',
        companyName: 'Tech Solutions',
        location: 'Bengaluru',
        salaryOffered: '10 LPA',
        submittedOn: '2024-12-01',
        status: 'Under Review',
    },
    {
        id: 2,
        quotationId: 'Q12346',
        jobTitle: 'Frontend Developer',
        companyName: 'Creative Minds',
        location: 'Pune',
        salaryOffered: '7 LPA',
        submittedOn: '2024-12-03',
        status: 'Accepted',
    },
    {
        id: 3,
        quotationId: 'Q12347',
        jobTitle: 'Project Manager',
        companyName: 'Global Tech',
        location: 'Mumbai',
        salaryOffered: '18 LPA',
        submittedOn: '2024-12-05',
        status: 'Rejected',
    },
    {
        id: 4,
        quotationId: 'Q12348',
        jobTitle: 'Quality Analyst',
        companyName: 'Reliable Systems',
        location: 'Noida',
        salaryOffered: '12 LPA',
        submittedOn: '2024-12-07',
        status: 'Under Review',
    },
    {
        id: 5,
        quotationId: 'Q12349',
        jobTitle: 'Product Manager',
        companyName: 'Visionary Products',
        location: 'Hyderabad',
        salaryOffered: '25 LPA',
        submittedOn: '2024-12-10',
        status: 'Accepted',
    },
];

export default function QuotationTable() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    const columns = [
        {
            field: 'quotationId',
            headerName: 'Quotation ID',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'jobTitle', headerName: 'Job Title', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'companyName', headerName: 'Company Name', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'location', headerName: 'Location', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'salaryOffered', headerName: 'Salary Offered', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'submittedOn', headerName: 'Submitted On', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'status', headerName: 'Status', minWidth: 150, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
