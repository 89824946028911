import React,{ useState} from 'react';
import { Paper, Avatar, Typography, Box, TextField, IconButton } from '@mui/material';
import avatarmen from '../../assets/avatarmen.jpg';
import SendIcon from '@mui/icons-material/Send';


const ChatDetails = ({ chatMessages, selectedChat }) => {

  const [ messageData,setMessageData] = useState('');

  const handenputChange = (event) => {
    setMessageData(event.target.value)
  }

  const handleSend = () => {
    // Logic to handle sending the message
    console.log("Message sent!");
  };

  console.log('messageData',messageData);
  

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Set the Paper to occupy full viewport height
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      {/* Fixed Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          borderBottom: '1px solid #ddd',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Avatar
          sx={{ marginRight: 2 }}
          src={avatarmen}
        >
          P
        </Avatar>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {selectedChat?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Last seen 10:30 pm
          </Typography>
        </Box>
      </Box>

      {/* Scrollable Chat Content */}
      <Box
        sx={{
          flexGrow: 1, // Allow this section to grow and take remaining space
          overflowY: 'auto', // Enable vertical scrolling
          padding: 2,
          backgroundColor: '#ffffff',
        }}
      >
        {chatMessages.map((chat) => (
          <Box
            key={chat.id}
            sx={{
              marginBottom: 2,
              display: 'flex',
              flexDirection: chat.sender === 'You' ? 'row-reverse' : 'row',
              alignItems: 'center',
            }}
          >
            {/* Avatar */}
            <Avatar
              sx={{ margin: chat.sender === 'You' ? '0 0 0 12px' : '0 12px 0 0' }}
              src={chat.avatar}
            >
              {chat.sender.charAt(0)}
            </Avatar>

            {/* Message Content */}
            <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '70%' }}>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: chat.sender === 'You' ? '#d1e7dd' : '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  wordBreak: 'break-word',
                }}
              >
                {chat.message}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ marginTop: '4px', alignSelf: chat.sender === 'You' ? 'flex-end' : 'flex-start' }}
              >
                {chat.timestamp}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
           
          }}
        >
          <TextField
            fullWidth
            placeholder="Enter message here..."
            variant="outlined"
            onChange={handenputChange}
            InputProps={{
              sx: {
                borderRadius: '2rem',
                backgroundColor: 'white',
              },
            }}
          />
          <IconButton onClick={handleSend} sx={{ marginLeft: '0.5rem' }}>
            <SendIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default ChatDetails;
