import React from 'react';
import { Box } from '@mui/material';
import SeekersTable from './SeekersTable';
import { useNavigate } from 'react-router-dom';

// Sample Job Seeker Payment Details Data
const rowsData = [
    {
        id: 1,
        jobSeekerName: 'Amit Sharma',
        email: 'amit.sharma@example.com',
        paymentDate: '2024-01-10',
        amountPaid: '₹1,000',
        paymentStatus: 'Completed',
        paymentMethod: 'UPI',
        transactionId: 'TXN123456',
    },
    {
        id: 2,
        jobSeekerName: 'Neha Verma',
        email: 'neha.verma@example.com',
        paymentDate: '2024-01-05',
        amountPaid: '₹1,500',
        paymentStatus: 'Pending',
        paymentMethod: 'Credit Card',
        transactionId: 'TXN789012',
    },
    {
        id: 3,
        jobSeekerName: 'Rohan Gupta',
        email: 'rohan.gupta@example.com',
        paymentDate: '2024-01-12',
        amountPaid: '₹2,000',
        paymentStatus: 'Completed',
        paymentMethod: 'Net Banking',
        transactionId: 'TXN345678',
    },
    {
        id: 4,
        jobSeekerName: 'Priya Singh',
        email: 'priya.singh@example.com',
        paymentDate: '2024-01-15',
        amountPaid: '₹1,200',
        paymentStatus: 'Completed',
        paymentMethod: 'Debit Card',
        transactionId: 'TXN9101112',
    },
    {
        id: 5,
        jobSeekerName: 'Vikram Desai',
        email: 'vikram.desai@example.com',
        paymentDate: '2024-01-18',
        amountPaid: '₹1,800',
        paymentStatus: 'Pending',
        paymentMethod: 'PayPal',
        transactionId: 'TXN13141516',
    },
];

export default function JobSeekerPaymentTable() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    // Updated Columns for Job Seeker Payment Details Table
    const columns = [
        {
            field: 'jobSeekerName',
            headerName: 'Name',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleClick('/jobseeker/paymentDetails')}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'paymentDate', headerName: 'Payment Date', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'amountPaid', headerName: 'Amount Paid', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'paymentStatus', headerName: 'Payment Status', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'paymentMethod', headerName: 'Payment Method', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'transactionId', headerName: 'Transaction ID', minWidth: 200, headerClassName: 'data-grid-header' },
    ];

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SeekersTable columns={columns} rows={rowsData} />
        </Box>
    );
}
