import React from 'react';

import RoutingComponent from './routes/routes';


const App = () => {

  return (
 
      <RoutingComponent />
  );
}

export default App;
