import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const CandidiateJobFilters = () => {
    const categories = [
        { name: 'Active', count: 120 },
        { name: 'Awaiting Review', count: 45 },
        { name: 'Reviewed', count: 87 },
        { name: 'Contracting', count: 34 },
        { name: 'Hired', count: 56 },
        { name: 'Rejected', count: 15 },
    ];

    return (
        <Paper
            elevation={0} // Paper elevation for subtle background shadow
            sx={{
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 0,
                marginBottom: 3,
            }}
        >
            {categories.map(({ name, count }, index) => (
                <Box
                    key={index}
                    sx={{
                        backgroundColor: '#e0f7fa',
                        borderRadius: 1,
                        minWidth: 180,
                        height: 50,
                        margin: 0.5,
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'text.primary',
                        transition: 'all 0.3s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#b2ebf2',
                            transform: 'scale(1.05)',
                            boxShadow: 3,
                        },
                    }}
                >
                    <Typography variant="body2">
                        {name}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 0.5,color:'black' }}>
                        {count}
                    </Typography>

                </Box>
            ))}
        </Paper>
    );
};

export default CandidiateJobFilters;
