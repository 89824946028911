import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';

const experiences = [
  {
    title: 'Frontend Developer',
    company: 'Tech Solutions',
    year: '2019 - Present',
    description:
      'Developing and maintaining user interfaces for web applications, ensuring a seamless and responsive user experience using modern front-end technologies such as React, HTML, CSS, and JavaScript.',
  },
  {
    title: 'Junior Frontend Developer',
    company: 'Creative Webs',
    year: '2017 - 2019',
    description:
      'Assisted in building interactive and user-friendly web interfaces, focusing on front-end design and optimization using HTML5, CSS3, and JavaScript. Collaborated with back-end developers to integrate APIs and improve website functionality.',
  },
  {
    title: 'Intern - Frontend Development',
    company: 'Innovative Studios',
    year: '2016 - 2017',
    description:
      'Worked as part of a team to develop responsive web pages and applications. Gained hands-on experience with web design tools, front-end frameworks, and content management systems (CMS).',
  },
];

const projects = [
  {
    id: 1,
    client: 'Nil Yeager',
    projectName: 'App design',
    startDate: '01/01/2015',
    dueDate: '10/15/2018',
    status: 'Work in Progress',
  },
  {
    id: 2,
    client: 'Mical',
    projectName: 'Coffee detail page',
    startDate: '21/07/2016',
    dueDate: '12/05/2018',
    status: 'Pending',
  },
  {
    id: 3,
    client: 'Lucy',
    projectName: 'Poster design',
    startDate: '18/03/2018',
    dueDate: '28/09/2018',
    status: 'Done',
  },
  {
    id: 4,
    client: 'ToodDoe',
    projectName: 'Bottle graphics',
    startDate: '02/10/2017',
    dueDate: '07/05/2018',
    status: 'Work in Progress',
  },
  {
    id: 5,
    client: 'Mical',
    projectName: 'Landing page',
    startDate: '17/01/2017',
    dueDate: '25/05/2021',
    status: 'Coming soon',
  },
];



const JobSeekerExpierence = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, display: 'flex', alignItems: 'center' }}>
        <WorkIcon sx={{ mr: 2 }} />
        Experience
      </Typography>
      <ul style={{ paddingInlineStart: '20px', margin: 0 }}>
        {experiences.map((experience, index) => (
          <li key={index} style={{ marginBottom: '16px', listStyleType: 'disc' }}>
            <Box sx={{ paddingLeft: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {experience.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {experience.company} | {experience.year}
              </Typography>
              <Typography variant="caption" sx={{ color: '#a1a4b5', marginTop: 0.5, textAlign: 'justify' }}>
                {experience.description}
              </Typography>
            </Box>
          </li>
        ))}
      </ul>

      <Box >
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, display: 'flex', alignItems: 'center' }}>
          <AssignmentIcon
            sx={{ mr: 2 }} />
          Projects
        </Typography>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Client</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Project Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Start Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Due Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.id}</TableCell>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.client}</TableCell>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.projectName}</TableCell>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.startDate}</TableCell>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.dueDate}</TableCell>
                  <TableCell sx={{ color: '#a1a4b5' }}>{project.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>

  );
};

export default JobSeekerExpierence;
