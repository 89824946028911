import React from 'react';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';

const Dashboard = () => {
    return (
        <AdminDrawerList>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}><h1>Welcome to the Dashboard</h1></div>

        </AdminDrawerList>
    );
};

export default Dashboard;
